import { Component, OnInit } from '@angular/core';
import { UploadService } from '../../services/upload.service';
import { faAngleDown, faAngleUp, faCheckCircle, faCircleNotch, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FileUploader } from '@pwc/forms';

@Component({
  selector: 'app-uploader-queue',
  templateUrl: './uploader-queue.component.html',
  styleUrls: ['./uploader-queue.component.scss']
})
export class UploaderQueueComponent implements OnInit {
  uploader: FileUploader;
  listVisible = false;

  faCircleNotch = faCircleNotch;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;
  faTimes = faTimes;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  constructor(private uploaderService: UploadService) {
  }

  ngOnInit(): void {
    this.uploader = this.uploaderService.uploader;
  }

  get isPending(): boolean {
    return this.uploaderService.isPending;
  }

  get isUploading(): boolean {
    return this.uploaderService.isUploading;
  }

  get isProcessing(): boolean {
    return this.uploaderService.progress === 100 && this.uploaderService.isUploading;
  }

  get totalItems(): number {
    return this.uploaderService.totalItemsCount;
  }

  get completedItems(): number {
    return this.uploaderService.completedItemsCount;
  }

  get progress(): string {
    return this.uploaderService.progress + '%';
  }

  get hasItems(): boolean {
    return this.uploaderService.hasItems;
  }

  get isError(): boolean {
    return this.uploaderService.isError;
  }

  get isSuccess(): boolean {
    return this.uploaderService.isSuccess;
  }

  emptyQueue() {
    this.uploaderService.removeAll();
  }

  hideList(): void {
    this.listVisible = false;
  }

  showList(): void {
    this.listVisible = true;
  }

  closeDialog() {
    if (this.isSuccess || this.isError) {
      this.emptyQueue();
    }

    this.hideList();
  }
}
