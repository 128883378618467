export enum RoutePath {
  SLASH = '/',
  LOGIN = 'login',
  NEW = 'new',
  CREATE = 'create',
  EDIT = 'edit',
  CONFIG = 'config',
  REPLY = 'reply',
  FOLLOW_UP = 'follow-up',
  VIEW = 'view',
  REPORT = 'report',

  DASHBOARD = 'dashboard',
  DASHBOARD_GENERAL = 'general',
  AUDIT = 'audit',
  AUDIT_FLOW = 'flow-management',
  AUDIT_ODV = 'odv',
  TASK = 'task',
  INFORMATIVE = 'informative',

  TEMPLATES = 'templates',
  NOTIFICATION = 'notifications'
}
