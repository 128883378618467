export enum AuthorityCodeEnum {
  ROLE_ODV_COMPONENTE = 'ROLE_ODV_COMPONENTE',
  ROLE_ODV_RESPONSABILE_231 = 'ROLE_ODV_RESPONSABILE_231',
  ROLE_ODV_RESPONSABILE_PROCESSO = 'ROLE_ODV_RESPONSABILE_PROCESSO',

  // REPORTS
  ROLE_ODV_DOCUMENT_TEMPLATE_READ = 'ROLE_ODV_DOCUMENT_TEMPLATE_READ',
  ROLE_ODV_DOCUMENT_TEMPLATE_UPDATE = 'ROLE_ODV_DOCUMENT_TEMPLATE_UPDATE',
  ROLE_ODV_DOCUMENT_TEMPLATE_DELETE = 'ROLE_ODV_DOCUMENT_TEMPLATE_DELETE',
  ROLE_ODV_DOCUMENT_CUSTOMER_MODEL_READ = 'ROLE_ODV_DOCUMENT_CUSTOMER_MODEL_READ',
  ROLE_ODV_DOCUMENT_CUSTOMER_MODEL_UPDATE = 'ROLE_ODV_DOCUMENT_CUSTOMER_MODEL_UPDATE',
  ROLE_ODV_DOCUMENT_CUSTOMER_MODEL_DELETE = 'ROLE_ODV_DOCUMENT_CUSTOMER_MODEL_DELETE'
}
