import { Injectable } from "@angular/core";
import { HttpService } from "@pwc/common";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends HttpService<any, any> {

  protected baseUrl: string = environment.authUrl;
  protected endpoint: string = 'notification';
  public onReadNotify: BehaviorSubject<boolean> = new BehaviorSubject(false);

  serializeFilters(obj) {
    const str = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
      }
    }
    return str.join('&');
  }

  buildFilters(activeFilters: object, config) {
    const filters = activeFilters || {};

    if (config && config.value) {
      filters[config.type] = config.value;
    } else {
      delete filters[config.type];
    }

    return {
      config: filters,
      params: `?${this.serializeFilters(filters)}`
    };
  }

  onMarkRead(id: number): Observable<any> {
    return this.post(`${id}/mark`, id);
  }

  deleteMultipleNotifications(idsBody: any[]): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + this.endpoint + 's?ids=' + idsBody.join(','));
  }

  convertModelToResource(model) {
    return { ...model };
  }

  convertResourceToModel(resource) {
    return { ...resource };
  }
}
