import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// REMOVE AFTER DEMO
if (window.location.search.indexOf('user=') > -1) {
  const user = window.location.search.split('user=')[1] || 'superuser';
  localStorage.setItem('user', user);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
