// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   appVersion: require('../../package.json').version,
//   authUrl: 'https://backend-dev.digitalsuite.pwc-tls.it',
//   apiUrl: 'https://odv-backend-dev.ds.digitalsuite.pwc-tls.it',
//   cookieDomain: 'pwc-tls.it',
//   cookiePath: '/',
//   frameworkUrl: 'https://dev.digitalsuite.pwc-tls.it',
//   dmsUrl: 'https://dms-dev.digitalsuite.pwc-tls.it',
//   notificationInterval: 60,
//   gmapsApiKey: 'AIzaSyDNbxQidi8Zl-iFZSJ_mYmG5FL059PaTIk'
// };

export const environment = {
  production: false,
  appVersion: require('../../package.json').version,
  authUrl: 'https://dev1.devds-backend.digitalsuite.pwc-tls.it',
  apiUrl: 'https://odv-backend-dev.ds.digitalsuite.pwc-tls.it',
  cookieDomain: 'pwc-tls.it',
  cookiePath: '/',
  frameworkUrl: 'https://dev1.devds.digitalsuite.pwc-tls.it',
  dmsUrl: 'https://dms.devds.digitalsuite.pwc-tls.it',
  notificationInterval: 60,
  gmapsApiKey: 'AIzaSyDNbxQidi8Zl-iFZSJ_mYmG5FL059PaTIk'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
