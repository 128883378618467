import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger,
  transition,
  animate,
  style,
  state
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '@pwc/security';
import {
  PwcIconsLibrary,
  piCog,
  piHome,
  piList,
  piMenuChecklist,
  piMenuCgRegistry,
  piDms,
  piDashboard,
  piMenuReport,
  piMenuBuilding,
  piMenuCompanyHierarchy,
  piMenuLinkedGraph,
  piMenuDossier,
  piOrganizationChart,
  piIcRegistro,
  piDpia,
  piDocumentale,
  piGlobeMap,
  piEngagement,
  piTreeView,
  piDocumentPage,
  piAvatar,
  piAudience,
  piBuilding,
  piCommunity
} from '@pwc/icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';
import { RoutePath } from '../../../enums/route-path.enum';
import { AuthorityCodeEnum } from '../../../enums/security/authority-code.enum';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  animations: [
    trigger('show', [
      state('void', style({ opacity: 0 })),
      state('true', style({ opacity: 1 })),
      transition(':enter', animate('250ms 750ms ease')),
      transition(':leave', animate('0ms ease'))
    ]),
    trigger('hide', [
      state('true', style({ opacity: 1 })),
      state('void', style({ opacity: 0 })),
      transition(':enter', animate('250ms 500ms ease')),
      transition(':leave', animate('250ms ease'))
    ]),
    trigger('grow', [
      state('true', style({ width: '100%' })),
      state('void', style({ width: 0 })),
      transition(':enter', animate('500ms ease')),
      transition(':leave', animate('500ms 250ms ease'))
    ])
  ]
})
export class NavMenuComponent implements OnInit {
  faAngleLeft = faAngleLeft;
  showBigMenu = true;
  showLabels = true;
  subscriptions: Subscription[] = [];
  moduleItemCollapsed = true;
  isComponenteOdv: boolean = false;
  isResponsabile231: boolean = false;
  isResponsabileProcesso: boolean = false;

  RoutePath = RoutePath;

  menuStructure: MenuStructure;

  constructor(
    private pwcIconsLibrary: PwcIconsLibrary,
    private router: Router,
    private authorizationService: AuthorizationService
  ) {
    this.pwcIconsLibrary.registerIcons([
      piOrganizationChart,
      piHome,
      piDashboard,
      piIcRegistro,
      piDpia,
      piDocumentale,
      piMenuChecklist,
      piList,
      piCog,
      piMenuCgRegistry,
      piDms,
      piMenuReport,
      piMenuBuilding,
      piMenuCompanyHierarchy,
      piMenuLinkedGraph,
      piMenuDossier,
      piGlobeMap,
      piEngagement,
      piTreeView,
      piDocumentPage,
      piAvatar,
      piAudience,
      piCommunity,
      piBuilding
    ]);
  }

  ngOnInit(): void {
    this.checkAuthorities();
    this.buildNavMenu();
  }

  buildNavMenu() {
    this.menuStructure = {
      appTitle: 'menu.label',
      appIcon: 'organization_chart',
      appBackTitle: 'menu.submenu',
      appBackIcon: 'home',
      appBackAction: this.navigateToFramework,
      sections: [
        {
          type: 'path',
          title: 'menu.dashboard.general.main-menu',
          icon: 'dashboard',
          hasSubmenu: false,
          path: RoutePath.SLASH + RoutePath.DASHBOARD + RoutePath.SLASH + RoutePath.DASHBOARD_GENERAL,
          visible: this.getAuthorities()
        },
        {
          type: 'path',
          title: 'menu.audit.audit-flussi',
          icon: 'dpia',
          hasSubmenu: false,
          path: RoutePath.SLASH + RoutePath.AUDIT + RoutePath.SLASH + RoutePath.AUDIT_FLOW,
          visible: this.sectionVisible()
        },
        {
          type: 'path',
          title: 'menu.audit.audit-odv',
          icon: 'ic_registro',
          hasSubmenu: false,
          path: RoutePath.SLASH + RoutePath.AUDIT + RoutePath.SLASH + RoutePath.AUDIT_ODV,
          visible: this.sectionVisible()
        },
        {
          type: 'path',
          title: 'menu.report.main-menu',
          icon: 'img',
          imgPath: '/assets/images/icons/newsReport_White.png',
          hasSubmenu: false,
          path: RoutePath.SLASH + RoutePath.REPORT,
          visible: this.checkIsComponenteOdv()
        },
        {
          type: 'path',
          title: 'menu.document-templates.main-menu',
          icon: 'document_page',
          hasSubmenu: false,
          path: RoutePath.SLASH + RoutePath.TEMPLATES,
          visible: this.checkIsComponenteOdv()
        },
        {
          type: 'action',
          title: 'menu.dms',
          icon: 'documentale',
          hasSubmenu: false,
          action: this.navigateToDms,
          visible: this.sectionVisible()
        }
        // {
        //   type: 'submenu',
        //   mainPath: RoutePath.SLASH + RoutePath.REGISTRY,
        //   title: 'menu.registry.main-menu',
        //   icon: 'avatar',
        //   hasSubmenu: true,
        //   collapsed: true,
        //   submenu: [
        //     {
        //       title: 'menu.registry.corporate-list',
        //       icon: 'community',
        //       hasSubmenu: false,
        //       path: RoutePath.SLASH + RoutePath.REGISTRY + RoutePath.SLASH + RoutePath.CORPORATE_LIST
        //     },
        //     {
        //       title: 'menu.registry.company-list',
        //       icon: 'building',
        //       hasSubmenu: false,
        //       path: RoutePath.SLASH + RoutePath.REGISTRY + RoutePath.SLASH + RoutePath.COMPANY_LIST
        //     },
        //     {
        //       title: 'menu.registry.people-list',
        //       icon: 'audience',
        //       hasSubmenu: false,
        //       path: RoutePath.SLASH + RoutePath.REGISTRY + RoutePath.SLASH + RoutePath.PEOPLE_LIST
        //     }
        //   ]
        // }
      ]
    };
  }

  sectionVisible() {
    return true;
  }

  checkIsComponenteOdv() {
    return this.isComponenteOdv;
  }

  navigateToFramework() {
    window.open(environment.frameworkUrl, '_blank');
  }

  navigateToDms(): void {
    window.open(environment.dmsUrl, '_blank');
  }

  onTransform() {
    this.showBigMenu = !this.showBigMenu;
  }

  setActive(path) {
    // TODO FIND A BETTER SOLUTION
    return this.router.url.indexOf(path) > -1;
  }

  private checkAuthorities(): void {
    this.subscriptions.push(this.authorizationService.checkAuthorities(AuthorityCodeEnum.ROLE_ODV_COMPONENTE)
      .subscribe((res) => {
        this.isComponenteOdv = res;
        this.buildNavMenu();
      }));
    this.subscriptions.push(this.authorizationService.checkAuthorities(AuthorityCodeEnum.ROLE_ODV_RESPONSABILE_231)
      .subscribe((res) => {
        this.isResponsabile231 = res;
        this.buildNavMenu();
      }));
    this.subscriptions.push(this.authorizationService.checkAuthorities(AuthorityCodeEnum.ROLE_ODV_RESPONSABILE_PROCESSO)
      .subscribe((res) => {
        this.isResponsabileProcesso = res;
        this.buildNavMenu();
      }));
  }

  getAuthorities(): boolean {
    let bool = true;
    if (this.isResponsabileProcesso) {
      if (!this.isComponenteOdv || !this.isResponsabile231) {
        bool = false;
      }
    }
    return bool;
  }
}

interface MenuStructure {
  appTitle: string;
  appIcon: string;
  appBackTitle: string;
  appBackIcon: string;
  appBackAction: any;
  sections: MenuItem[];
}

interface MenuItem {
  type?: string;
  mainPath?: string;
  title: string;
  icon: string;
  hasSubmenu: boolean;
  path?: string;
  collapsed?: boolean;
  submenu?: MenuItem[];
  action?: any;
  visible?: any;
  imgPath?: string;
}
