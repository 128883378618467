import { Component, OnInit } from '@angular/core';
import { piAdd, PwcIconsLibrary } from '@pwc/icons';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  constructor(private pwcIconsLibrary: PwcIconsLibrary) {
    this.pwcIconsLibrary.registerIcons([
      piAdd
    ]);
   }

  ngOnInit(): void { }
}
