<div class="row justify-content-between">
  <div class="col">
    <div class="row header-content-left">
      <div class="col-auto">
        <div class="welcomeText">
          <span translate>header.welcome-message</span>
          <span *ngIf="user">{{user.firstName}} {{user.lastName}}</span>(<a (click)="logout()" class="user-logout" translate>header.logout</a>)
        </div>
      </div>
      <div class="col-auto">
        <div class="sep vertical"></div>
      </div>
      <div class="col header-ngselect">
        <ng-select
          bindLabel="name"
          class="company-select"
          [items]="societies"
          [(ngModel)]="selectedSociety"
          (change)="setCurrentSociety()"
          [clearable]="false"
        >
        </ng-select>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="row">
      <div>
        <app-uploader-queue></app-uploader-queue>
        <pwc-downloader-queue></pwc-downloader-queue>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="header-actions">
      <div
        class="notification-bell"
        routerLink="/notifications"
        [ngbTooltip]="getNotificationStatus()">
        <span class="badge badge-danger pointer" *ngIf="notificationCount>0">{{ notificationCount }}</span>
       <!--  <img src="/assets/images/icons/bell.svg" class="notification-image"> -->
       <pwc-icon *ngIf="notificationCount==0; else bell2;" name="bell"></pwc-icon>
       <ng-template #bell2>
        <pwc-icon name="bell_2"></pwc-icon>
       </ng-template>
      </div>
      <div class="language-selector">
        <ng-select name="languageSelector"
                   autofocus="false"
                   noAutoComplete="true"
                   bindValue="code"
                   bindLabel="description"
                   class="lang-select"
                   (change)="changeLanguage()"
                   [items]="languages"
                   [searchable]="false"
                   [clearable]="false"
                   [(ngModel)]="currentLanguage">
        </ng-select>
      </div>
      <div class="pointer" ngbDropdown #myDrop="ngbDropdown" display="dynamic" placement="bottom-right">
        <div class="baloon" ngbDropdownToggle id="dropdownManual">
          {{userInitials|uppercase}}
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdownManual">
          <button ngbDropdownItem (click)="navigateToChangePassword()" translate>header.change-password</button>
          <button ngbDropdownItem (click)="logout()" translate>header.logout</button>
        </div>
      </div>
      <!--<div class="p-3">
        <input type="text" class="form-control search" value="" placeholder="Ricerca">
      </div>-->
    </div>
  </div>
</div>
