<div class="nav-menu" *ngIf="showBigMenu" [@hide] [@grow]>
  <div class="nav-menu-wrapper">
    <div class="nav-menu-element nav-menu-app">
      <a class="nav-menu-link" [ngClass]="{ 'active': !moduleItemCollapsed }"
         (click)="moduleItemCollapsed = !moduleItemCollapsed" [attr.aria-expanded]="!moduleItemCollapsed">
        <pwc-icon name="{{menuStructure.appIcon}}"></pwc-icon>
        <span translate>{{ menuStructure.appTitle }}</span>
      </a>
      <div class="nav-submenu back-to-ds" [ngbCollapse]="moduleItemCollapsed">
        <a class="nav-menu-link" (click)="menuStructure.appBackAction()">
          <pwc-icon name="{{menuStructure.appBackIcon}}"></pwc-icon>
          <span translate>{{menuStructure.appBackTitle}}</span>
        </a>
      </div>
    </div>
    <div class="nav-menu-element" *ngFor="let section of menuStructure.sections;">
      <ng-container [ngSwitch]="section.type" *ngIf="section.visible">
        <ng-container *ngSwitchCase="'path'">
          <a class="nav-menu-link" routerLinkActive="active" [routerLink]="section.path">
            <ng-container *ngIf="section.icon === 'img'; else iconTpl;">
              <img src="{{section.imgPath}}" />
            </ng-container>
            <ng-template #iconTpl>
              <pwc-icon name="{{section.icon}}"></pwc-icon>
            </ng-template>
            <span translate>{{section.title}}</span>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="'submenu'">
          <a class="nav-menu-link nav-menu-toggle"
             [ngClass]="{ 'active': setActive(section.mainPath) }"
             (click)="section.collapsed = !section.collapsed" [attr.aria-expanded]="section.collapsed">
            <pwc-icon name="{{section.icon}}"></pwc-icon>
            <span translate>{{section.title}}</span>
            <img class="chevron" [ngClass]="{ 'up': !section.collapsed }" src="/assets/images/icons/down-arrow.svg">
          </a>
          <div class="nav-submenu" [ngbCollapse]="section.collapsed">
            <div *ngFor="let item of section.submenu">
              <a class="nav-menu-link" routerLinkActive="active" [routerLink]="item.path">
                <pwc-icon name="{{item.icon}}"></pwc-icon>
                <span translate>{{item.title}}</span>
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'action'">
          <a class="nav-menu-link" (click)="section.action()">
            <pwc-icon name="{{section.icon}}"></pwc-icon>
            <span translate>{{section.title}}</span>
          </a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div class="nav-menu-small" *ngIf="!showBigMenu" [@show]>
  <div class="small-element main-link-app">
    <div class="small-element-trigger">
      <pwc-icon name="{{menuStructure.appIcon}}"></pwc-icon>
    </div>
    <div class="small-element-content">
      <div class="small-element-title" (click)="moduleItemCollapsed = !moduleItemCollapsed" [attr.aria-expanded]="moduleItemCollapsed">
        <span translate>{{menuStructure.appTitle}}</span>
        <img class="chevron" [ngClass]="{ 'up': !moduleItemCollapsed }" src="/assets/images/icons/down-arrow.svg">
      </div>
      <div class="small-nav-submenu" [ngbCollapse]="moduleItemCollapsed">
        <div class="submenu-element" (click)="menuStructure.appBackAction()">
          <a class="nav-menu-link">
            <pwc-icon name="{{menuStructure.appBackIcon}}"></pwc-icon>
            <span translate>{{menuStructure.appBackTitle}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let section of menuStructure.sections">
    <ng-container [ngSwitch]="section.type" *ngIf="section.visible">
      <ng-container *ngSwitchCase="'path'">
        <div class="small-element" routerLinkActive="active" [routerLink]="section.path">
          <div class="small-element-trigger">
            <ng-container *ngIf="section.icon === 'img'; else iconTpl;">
              <img src="{{section.imgPath}}" />
            </ng-container>
            <ng-template #iconTpl>
              <pwc-icon name="{{section.icon}}"></pwc-icon>
            </ng-template>
          </div>
          <div class="small-element-content">
            <div class="small-element-title">
              <span translate>{{section.title}}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'submenu'">
        <div class="small-element" routerLinkActive="active">
          <div class="small-element-trigger">
            <ng-container *ngIf="section.icon === 'img'; else iconTpl;">
              <img src="{{section.imgPath}}" />
            </ng-container>
            <ng-template #iconTpl>
              <pwc-icon name="{{section.icon}}"></pwc-icon>
            </ng-template>
          </div>
          <div class="small-element-content">
            <div class="small-element-title" (click)="section.collapsed = !section.collapsed" [attr.aria-expanded]="section.collapsed">
              <span translate>{{section.title}}</span>
              <img class="chevron" [ngClass]="{ 'up': !section.collapsed }" src="/assets/images/icons/down-arrow.svg">
            </div>
            <div class="small-nav-submenu" [ngbCollapse]="section.collapsed">
              <div class="submenu-element" routerLinkActive="active" [routerLink]="item.path" *ngFor="let item of section.submenu">
                <a class="nav-menu-link">
                  <pwc-icon name="{{item.icon}}"></pwc-icon>
                  <span translate>{{item.title}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'action'">
        <div class="small-element main-link-app" (click)="section.action()">
          <div class="small-element-trigger">
            <pwc-icon name="{{section.icon}}"></pwc-icon>
          </div>
          <div class="small-element-content">
            <div class="small-element-title">
              <span translate>{{section.title}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<div class="fixed-balloon" [ngClass]="{ 'translated': !showBigMenu }" (click)="onTransform()">
  <fa-icon [icon]="faAngleLeft" class="icon"></fa-icon>
</div>
