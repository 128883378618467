import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@pwc/i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentLanguage: string;

  constructor(
    private i18n: I18nService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('it');
  }

  ngOnInit(): void {
    this.i18n.lang$.subscribe((lang: string) => {
      this.currentLanguage = lang;
    });
  }

  useLanguage(language: string) {
    this.translate.use(language.toLowerCase());
  }
}
