import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule, DOWNLOAD_BASEURL } from '@pwc/common';
import { LayoutModule, UIModule } from '@pwc/ui';
import { HttpLoaderFactory, I18nModule } from '@pwc/i18n';
import { ErrorInterceptor, JwtInterceptor } from '@pwc/security';
import { PwcIconsModule } from '@pwc/icons';
import { FormsModule } from '@pwc/forms';
import { environment } from '../environments/environment';
// CUSTOM MODULES
import { AppRoutingModule } from './app-routing.module';
// CUSTOM COMPONENTS
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavMenuComponent } from './components/layout/nav-menu/nav-menu.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { UploaderQueueComponent } from './components/uploader-queue/uploader-queue.component';
// CUSTOM SERVICES
import { AuthenticationServiceConfig } from './security/authentication-service-config';
// CUSTOM PIPES
import { TruncatePipe } from './pipes/truncate.pipe';
import { CookieService } from 'ngx-cookie-service';
import { AgmCoreModule } from '@agm/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NavMenuComponent,
    HeaderComponent,
    TruncatePipe,
    UploaderQueueComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    CommonModule,
    LayoutModule,
    UIModule,
    PwcIconsModule,
    I18nModule,
    NgSelectModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({level: NgxLoggerLevel.DEBUG}),
    AgmCoreModule.forRoot({
      apiKey: environment.gmapsApiKey
    }),
    ToastrModule.forRoot(),
  ],
  providers: [
    {provide: 'FRAMEWORK_BASEURL', useValue: environment.authUrl},
    {provide: 'API_BASEURL', useValue: environment.apiUrl},
    {provide: 'AUTH_OPTIONS', useClass: AuthenticationServiceConfig, multi: false},
    {provide: DOWNLOAD_BASEURL, useValue: environment.apiUrl},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    TruncatePipe,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
