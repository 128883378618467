import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard, PasswordManagerGuard } from '@pwc/security';
import { RoutePath } from './enums/route-path.enum';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthorityCodeEnum } from './enums/security/authority-code.enum';

const routes: Routes = [
  {
    path: RoutePath.LOGIN,
    loadChildren: () => import('./pages/login/login.module').then(
      m => m.LoginModule
    )
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard, PasswordManagerGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard, PasswordManagerGuard],
    children: [
      {
        path: '',
        redirectTo: RoutePath.DASHBOARD + RoutePath.SLASH + RoutePath.DASHBOARD_GENERAL,
        pathMatch: 'full'
      },
      {
        path: RoutePath.DASHBOARD,
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(
          m => m.DashboardModule
        )
      },
      {
        path: RoutePath.NOTIFICATION,
        loadChildren: () => import('./pages/notification-page/notification.module').then(
          m => m.NotificationModule
        )
      },
      {
        path: RoutePath.AUDIT,
        loadChildren: () => import('./pages/audit/audit.module').then(
          m => m.AuditModule
        )
      },
      {
        path: RoutePath.TEMPLATES,
        loadChildren: () => import('./pages/document-templates/document-templates.module').then(
          m => m.DocumentTemplatesModule
        ),
        data: {
          authorities: [
            AuthorityCodeEnum.ROLE_ODV_COMPONENTE
          ],
          redirect: [
            RoutePath.AUDIT,
            RoutePath.AUDIT_ODV
          ]
        }
      },
      {
        path: RoutePath.REPORT,
        loadChildren: () => import('./pages/report/report.module').then(
          m => m.ReportModule
        ),
        data: {
          authorities: [
            AuthorityCodeEnum.ROLE_ODV_COMPONENTE
          ],
          redirect: [
            RoutePath.AUDIT,
            RoutePath.AUDIT_ODV
          ]
        }
      },
    ]
  },
  { path: '*', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
