<div *ngIf="hasItems" class="uploader-queue">
  <div class="uploader-queue-header d-flex justify-content-between">
    <div style="line-height: 22px">
      <span *ngIf="isUploading">Caricamento in corso...</span>
      <span *ngIf="isPending">Caricamento in attesa</span>
      <span *ngIf="isSuccess || isError">Caricamento completato</span>
    </div>
    <div class="uploader-queue-actions">
      <button class="btn btn-sm" type="button" (click)="hideList()" *ngIf="listVisible">
        <fa-icon [icon]="faAngleDown"></fa-icon>
      </button>
      <button class="btn btn-sm" type="button" (click)="showList()" *ngIf="!listVisible">
        <fa-icon [icon]="faAngleUp"></fa-icon>
      </button>
      <button class="btn btn-sm" type="button" (click)="closeDialog()">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </div>

  <ul class="uploader-queue-file-list" *ngIf="hasItems && listVisible">
    <li *ngFor="let item of uploader.queue; let i = index" class="uploader-queue-file d-flex justify-content-between">
      <div class="file-name" [ngClass]="{'text-danger': item.isError}">
        {{item?.file?.name}}
      </div>
      <div class="uploader-queue-icon">
        <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="item.isUploading"></fa-icon>
        <fa-icon [icon]="faTimesCircle" *ngIf="item.isError" class="text-danger"></fa-icon>
        <fa-icon [icon]="faCheckCircle" *ngIf="item.isSuccess" class="text-success"></fa-icon>
      </div>
    </li>
  </ul>
  <div class="uploader-queue-summary">
    <div class="d-flex justify-content-between">
      <div>
        <span *ngIf="!isSuccess && !isError && isProcessing">Elaborazione in corso&hellip;</span>
        <span *ngIf="!isSuccess && !isError && !isProcessing">{{progress}} completo ({{completedItems}}
          di {{totalItems}} file)</span>
        <span *ngIf="isSuccess || isError">Caricamento completato</span>
      </div>
      <div class="uploader-queue-icon">
        <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="isUploading"></fa-icon>
        <fa-icon [icon]="faTimesCircle" *ngIf="isError" class="text-danger"></fa-icon>
        <fa-icon [icon]="faCheckCircle" *ngIf="isSuccess" class="text-success"></fa-icon>
      </div>
    </div>
  </div>
</div>
