import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SocietiesService, Society, User } from '@pwc/common';
import { AuthenticationService, AuthorizationService, IdleTimeoutService } from '@pwc/security';
import { Language, LanguageCode } from '@pwc/i18n';
import { environment } from '../../../../environments/environment';
import { Society as SocietyModel } from '../../../models/society';
import { NotificationService } from 'src/app/services/notification.service';
import { piBell, piBell2, PwcIconsLibrary } from '@pwc/icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  languages: Language[] = [];
  currentLanguage: string = 'IT';
  it: Language = { id: '1', code: LanguageCode.IT, description: 'IT' };
  en: Language = { id: '2', code: LanguageCode.EN, description: 'EN' };
  user: User;
  userInitials: string = '';
  notificationCount: number = 0;
  selectedSociety: Society;
  societies: SocietyModel[] = [];
  societyAlfa: SocietyModel = { id: '1', name: 'Società Alfa', isParentCompany: 'NO', selected: false };
  societyBeta: SocietyModel = { id: '2', name: 'Società Beta', isParentCompany: 'NO', selected: false };

  constructor(private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private http: HttpClient,
    private translate: TranslateService,
    private societiesService: SocietiesService,
    public notificationService: NotificationService,
    private pwcIconsLibrary: PwcIconsLibrary,
    private idleService: IdleTimeoutService
  ) {
    this.idleService.start();
    this.pwcIconsLibrary.registerIcons([
      piBell,
      piBell2
    ]);
  }

  ngOnInit(): void {
    this.languages.push(this.it);
    this.languages.push(this.en);
    this.societies.push(this.societyAlfa);
    this.societies.push(this.societyBeta);
    this.selectedSociety = this.societyAlfa;
    this.initLoggedUser();

    this.initNotificationsCount();
    this.initSocieties();
    this.notificationService.onReadNotify.subscribe((read) => {
      if (read) {
        this.http.get<number>(`${environment.authUrl}/notification/count`)
          .pipe(first())
          .subscribe((count: number) => {
            this.notificationCount = count;
          }, (error) => {
            console.error(error);
          });
      }
    });
  }

  changeLanguage(): void {
    this.translate.use(this.currentLanguage.toLowerCase());
  }

  private initLoggedUser(): void {
    this.userInitials = '';
    this.authenticationService.dataUser()
      .subscribe((user: User) => {
        this.user = user;

        if (this.user != null) {
          if (this.user.firstName != null) {
            this.userInitials += this.user.firstName.substr(0, 1);
          }
          if (this.user.lastName != null) {
            this.userInitials += this.user.lastName.substr(0, 1);
          }
        }
      });
  }

  logout(): void {
    this.authenticationService.logout(null);
  }

  navigateToChangePassword(): void {
    window.location.href = `${environment.frameworkUrl}/account/change-password`;
  }

  private initNotificationsCount(): void {
    if (environment.notificationInterval > 0) {
      this.http.get<number>(`${environment.authUrl}/notification/count`)
        .pipe(first())
        .subscribe((count: number) => {
          this.notificationCount = count;
        }, (error) => {
          console.error(error);
        });
      setInterval(() => {
        this.http.get<number>(`${environment.authUrl}/notification/count`)
          .pipe(first())
          .subscribe((count: number) => {
            this.notificationCount = count;
          }, (error) => {
            console.error(error);
          });
      }, environment.notificationInterval * 1000);
    }
  }

  getNotificationStatus(): string {
    let label = '';
    const status = this.notificationCount > 0 ? 'header.notification.new' : 'header.notification.empty';
    this.translate.get(status).subscribe((res: string) => {
      label = res;
    });

    return label;
  }

  private initSocieties(): void {
    this.societiesService.getUserCompany()
      .subscribe((data: SocietyModel[]) => {
        this.societies = [];
        data.forEach((society) => {
          if (society.isParentCompany === 'NO') {
            this.societies.push(society);
          }
        });

        if (this.societies.length > 0) {
          this.selectedSociety = this.societies[0];
          this.societiesService.setSociety(this.selectedSociety);
        }
      });
  }

  setCurrentSociety(): void {
    this.societiesService.setSociety(this.selectedSociety);
  }
}
