import { AuthenticationOptions } from '@pwc/security';

import { environment } from '../../environments/environment';

export class AuthenticationServiceConfig implements AuthenticationOptions {
  cookieDomain = environment.cookieDomain;
  cookiePath = environment.cookiePath;
  frontendUrl = environment.frameworkUrl;
  url = environment.authUrl;
  loginUrl = '';
}
